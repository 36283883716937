@font-face {
  font-family: "Inter_300";
  src: url("./fonts/Inter 300.woff2");
}
@font-face {
  font-family: "Inter_500";
  src: url("./fonts/Inter 500.woff2");
}
@font-face {
  font-family: "Inter_700";
  src: url("./fonts/Inter 700.woff2");
}
/* Street font */
@font-face {
  font-family: "Street_300";
  src: url("./fonts/Street_300.woff2");
}
@font-face {
  font-family: "Street_500";
  src: url("./fonts/Street_500.woff2");
}
@font-face {
  font-family: "Street_700";
  src: url("./fonts/Street_700.woff2");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Street_500", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* !Media query a partir de 568px */
@media only screen and (min-width: 568px) {
  * {
    font-size: 16px;
  }
}
/* !Media query a partir de 768px */
@media only screen and (min-width: 868px) {
  * {
    font-size: 18px;
  }
}
/* !Media query a partir de 768px */
@media only screen and (min-width: 1366px) {
  * {
    font-size: 20px;
  }
}
/* !Media query a partir de 768px */
/* @media only screen and (min-width: 1920px) {
  * {
    font-size: 22px;
  }
} */
